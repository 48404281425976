<template>
  <div class="zuomian">
    <div class="jieshao">
      桌面虚拟化是典型的云计算应用，通过虚拟化技术，在远程服务器上运行用户的操作系统和应用软件，采用桌面协议将远端的桌面视图以图像的方式传送到用户端设备上进行显示，同时，用户端的输入通过网络传递至服务侧进行处理，并更新桌面视图内容。
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/9.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">卓越的终端用户体验</div>
      <div class="li-text">
        用户可以使用任何设备（包括平板电脑、智能手机、笔记本电脑、个人电脑等）访问桌面和应用
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">统一管理和安全控制</div>
      <div class="li-text">
        企业IT管理员能从单个控制台轻松地管理现有策略及基建，透过执行统一安全规范、政策及权限，妥善监控、配置及部署云端代管的桌面平台及应用程序
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据安全和业务连续性</div>
      <div class="li-text">
        全面的网络安全方案、本地和异地备份/灾备方案/终端备份方案以及一站式的运维管理服务，保证企业数据得到最高等级的安全防护以及7×24×365的业务连续性
      </div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">统一门户</div>
      <div class="li-text">
        为云资源使用者、资源监管方、资源运营方提供统一的访问门户，避免因为云服务商的不同带来云资源服务使用和操作中的差异化和复杂性，确保云资源服务过程中用户使用体验的一致性
      </div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/10.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">运营管理</div>
      <div class="li-text">
        云监管平台作为云租户的统一访问门户，整合后端服务商的云资源和服务，以统一的“服务超市”的模式实现云端的资源、应用和数据服务的选购、服务计费、服务订单管理等
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">云服务商能力考评</div>
      <div class="li-text">
        云监管平台中建立云服务性价比、运维水平、安全审查、用户评价“四维一体”的服务商考评体系，以考评指标为牵引，以考评结果为导向，促进云服务商综合服务质量的不断提升
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">业务监管</div>
      <div class="li-text">
        业务监管主要从业务的可用性以及健康度等多个维度对业务进行整体监管，可选择性的启动或停止监控业务
        <br />业务的平均可用率 <br />业务的平均响应时间
        <br />业务故障原因统计业务状态的统计 <br />业务状态列表
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.zuomian {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>