<template>
  <div class="wulianwang">
    <div class="biaoti">
      <span class="el-icon-caret-bottom"></span>
      需求背景
    </div>
    <div class="text">
      物联网总线平台I-Ching，于2019年在成都汉康信息产业有限公司经营范围内的水务、政务、环卫、应急、医疗多个领域提出的“解决数据集成”和“更高智慧应用”要求的背景下产生，凭借在核心业务行业10年的落地实施与交付经验，基于物联网总线的设计完成。既是为了规避与省市相关大数据部门的已建或在建的大数据体系冲突，也是为了融合市场成熟的物联网技术、API网关技术，聚焦“跨行业”、“物联网设备和数据集成安全管理”的创新设计和改进，解决上述的行业瓶颈，赋能智慧城市“一横N纵”的发展。
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      产品概述
    </div>
    <div class="text">
      I-Ching包含：“设备接入管理”、“设备安全认证管理”、“设备监测与维护管理”、“边缘计算管理”、“物联网数据集成管理”、“分析组合
      管理”、“一张图网络管理”、“总线运营管理”等几大功能/应用。物联网总线平台，主要应用赋能基于各市级、县级政府和基于购买服务模式
      的“政务、水务、消防、安防、交通”等B端G端用户。帮助政府和企业构建一体化的物联、数据、应用集成管理大平台。
    </div>
    <img
      class="gaishu"
      src="../../../../assets/imgs/chanping/shuiwu/wulianwang (3).png"
      alt=""
    />
    <div class="text mt30">
      I-Ching主体分为3大模块，1、DA架构的数据总线中台；2、软件中间件技术的融合智慧网关；3、设备管理中心。<br />
      I-Ching是支撑行业客户ABCD（Applications /Business /Clouds /Devices）
      全面集成的数据和设备管理技术融合平台。
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      技术架构
    </div>
    <img
      class="jiagou"
      src="../../../../assets/imgs/chanping/shuiwu/wulianwang (4).png"
      alt=""
    />
    <div class="li mt30">
      <div class="dian"></div>
      <div class="li-title">全量数据融合</div>
      <div class="li-text">
        提供轻量化数据、消息、API和物联设备的融合集成能力。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">统一平台</div>
      <div class="li-text">
        一站式集成平台，以设备中心、数据中台、应用中台三层搭建模式。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">配置式集成</div>
      <div class="li-text">
        通过配置式集成设备，大幅降低集成工作量，降低了对维护人员的技能要求。<br />
        通过配置式集成开发，大幅降低定制开发难度，降低了对实施人员的技能要求。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">集成开放</div>
      <div class="li-text">
        连接数据库、中间件、公有云，支持API、消息、文件操作等多种协议间的互转。
      </div>
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      应用架构
    </div>
    <img
      class="yingyong"
      src="../../../../assets/imgs/chanping/shuiwu/wulianwang (1).png"
      alt=""
    />
    <div class="li mt30">
      <div class="dian"></div>
      <div class="li-title">
        物联网融合管理中台 感知设备融合管理与数据“让数据通达”
      </div>
      <div class="li-text">
        新、旧物联网设备融合管理<br />
        采集数据分类疏导 <br />
        边缘计算与反向控制 <br />
        精美可视化
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">
        物联网总线服务管理中台 感知数据轻量化加工订阅服务“让数据服务”
      </div>
      <div class="li-text">
        微服务化订阅服务<br />
        标准化、模板化精美数据服务类型<br />
        专网 溯源服务管理<br />
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">
        物联网总线大数据平台 感知数据全域共享、交换、交易“让数据变现”
      </div>
      <div class="li-text">
        搭建数据权属认证安全平台架构<br />
        更丰富的数据模型及服务模式<br />
        专网+公网 数据服务共享交换<br />
        第三方管理运营
      </div>
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      系统特点
    </div>
    <div class="li mt30">
      <div class="dian"></div>
      <div class="li-title">多源数据融合</div>
      <div class="li-text">
        支持多种数据源和数据更是的连接适配能力，高效适配各种类型的异构数据库、文件、大数据、日志数据、实现结构化数据集成，批量数据与实时数据采集。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">海量设备接入</div>
      <div class="li-text">
        提供接入服务的等比横向扩容，平台本身支持物联设备无上限接入管理。<br />
        平台高并发响应时间延迟&lt;3秒。 <br />
        增值集成设备运行、生产数据以及能效分析、寿命预测等机器学习或算法能力。<br />
        提供反向控制功能接口，可集成具备相关技术支持的厂商设备，实现全自动管理。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">插件自适配</div>
      <div class="li-text">
        提供客户自主选择的数据管理模式（如加密模式）。<br />
        提供数据源插件自适配支持，能快速解决用户的特殊数据。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">可视化流程定义</div>
      <div class="li-text">
        提供图形化的集成开发环境，支持70种以上数据处理、可视化组件。<br />
        提供支持编排复杂的数据处理流程的服务。<br />
        提供数据处理流程中友好管控梳理、分析、分类、分流等服务。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">边缘计算</div>
      <div class="li-text">
        提供边缘计算管理能力。<br />
        提高“端”、“边”的信息预处理、预加载能力和平台任务处理量。<br />
        减轻“中心”的算力消耗、传输消耗及安全风险。
      </div>
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      运行环境
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">多源数据融合</div>
      <div class="li-text">
        支持多种数据源和数据更是的连接适配能力，高效适配各种类型的异构数据库、文件、大数据、日志数据、实现结构化数据集成，批量数据与实时数据采集。具体配置要求参见下图
      </div>
    </div>
    <img
      class="yunsuan"
      src="../../../../assets/imgs/chanping/shuiwu/wulianwang (5).png"
      alt=""
    />
    <div class="li mt30">
      <div class="dian"></div>
      <div class="li-title">服务器环境</div>
      <div class="li-text">
        服务端对硬件配置、操作系统、软件环境、应用服务器和数据库均有要求，具体配置要求参见下图。
      </div>
    </div>
    <img
      class="fuwuqi"
      src="../../../../assets/imgs/chanping/shuiwu/wulianwang (6).png"
      alt=""
    />
    <div class="li mt30">
      <div class="dian"></div>
      <div class="li-title">客户端环境</div>
      <div class="li-text">
        浏览器：推荐使用Chrome65及以上，IE11+ <br />
        硬件配置：4G内存以上， 1G以上显存
      </div>
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      经典场景
    </div>
    <div class="text">
      水务物联网 <br />
      水务业态场景中存在人员手动操作过多，作业监管跟踪困难，成本增加；各区段作业考核无统一标准，作业过程信息交互慢而乱，形成作业空窗期等问题。水务集团需要借助完善的水务物联网、大数据智能决策等技术全面了解水务业态运作实时状况，确保关键资产和HSE（健康、安全与环境管理体系）安全与良好状态。
    </div>
    <img
      class="changjing"
      src="../../../../assets/imgs/chanping/shuiwu/wulianwang (2).png"
      alt=""
    />
    <div class="text mt30">
      汉康推出集成I-Ching产品的智慧水务解决方案。
      <br />
      1、解决隧道管理、管网管理、巡查保养管理、排水户管理各板块业务独立，其下各类传感器、PLC、SCADA、视频、手持终端等物联网设备集成管理（设备中心管理）的需求，友好融合设备状态(离线、负载饱和度、异常告警)、基础信息（位置、运行参数）、指令消息、第三方输入消息、参数建模分析结果，可视化设备运行大数据分析。
      <br />
      2、解决水务作业流程联动时效和人员统一考核的需求，先通过融合基础图层（易涝区、网格化作业区）、终端预警、预置流程及状态等信息，实现点、线、面三位一体绑定、自动化驱动事件响应、流程传达、人员提醒等，提升作业流程人效；再自动记录、参数分析
      “事前”、“事中”、“事后”过程及结果，完成自动化考核。
      <br />
      3、解决水务汛期、灾害突发事件时，应急调度的响应及时到位跟踪问题。在传统水务体系中自由扩展、增加如人员提醒、范围锁定、路线记录等功能，还可配合交通、气象等实时数据完成自动化路线调整、就绪确认等功能。
    </div>
    <div class="text mt30">
      环卫物联网<br />
      能投环卫目前面临接入业务多样化、场景复杂化、网络边界消失等问题，传统网络方案已经不能满足新时代环卫的要求。
    </div>
    <div class="text mt30">
      汉康推出集成I-Ching产品的数字环卫解决方案。 <br />
      1、解决如何智能运营的问题，无缝集成市面上任意GPS定位、油耗监测、视频等厂家设备，辅助运行轨迹、线路优化算法、自动分析调度等物联网相关技术，融合真实数据、智能指挥、直观呈现来优化人员、作业事件、物资三位一体管理和时效成本考核。
      <br />
      2、解决设施管理维护问题，实现设施全局和可视化管理，辅助异常自动化监管、设施使用寿命预警等技术，提升设施使用效率和节约成本。
      <br />
      3、解决企业运营过程中业务量增大、战略调整时的整体能效管理问题，融合物联网、AI、大数据等新ICT技术，构建能源调度、设备负载、环境监测、人流密度等多维分析模型，以数据驱动流程改进的模式实现智慧节能。
      <br />
      4、辅助解决企业无纸化智能办公的问题，打造联接组织、物质、日常办公的统一工作平台，构建的安全、开放、便捷的企业级信息化工作环境。
    </div>
    <img
      class="dibu"
      src="../../../../assets/imgs/chanping/shuiwu/wulianwang (7).png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.gaishu {
  width: 100%;
  height: 384px;
  margin-top: 30px;
}
.jiagou {
  width: 100%;
  height: 508px;
  margin-top: 30px;
}
.yingyong {
  width: 100%;
  height: 424px;
  margin-top: 30px;
}
.yunsuan {
  width: 100%;
  height: 214px;
  margin-top: 30px;
}
.fuwuqi {
  width: 100%;
  height: 320px;
  margin-top: 30px;
}
.changjing {
  width: 100%;
  height: 390px;
  margin-top: 30px;
}
.dibu {
  width: 100%;
  height: 410px;
  margin-top: 30px;
}
.wulianwang {
  width: 870px;
  padding-bottom: 60px;
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>