<template>
  <div class="qiye">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/11.png" alt="" />
    </div>
    <div class="jieshao">
      将数据从来源端经过抽取（extract）、转换（transform）、加载（load）至目的端的过程。
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据传输</div>
      <div class="li-text">
        为了保证数据的正确传输和传输性能，必须有传输管理功能，包括了传输协议管理和控制，传输过程控、数据传输加密、压缩、网络故障检测和连接共享等功能
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">会话管理</div>
      <div class="li-text">
        为了能提供数据的交换方式如主动（推）、被动（拉）；支持定时和实时策略，支持超时控制管理等功能，数据交换平台必须有会话策略、会话控制和管理功能
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据管理</div>
      <div class="li-text">
        为了数据能按标准进行交换，能按照部署的路由规则进行交换，必须有数据路由、数据模板管理、数据解析、数据转换等功能
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">系统管理</div>
      <div class="li-text">
        包括运行管理、部署管理、服务管理、系统日志等功能。为了和安全平台结合，还必须有与安全平台的接口设计功能
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">信息共享</div>
      <div class="li-text">
        数据汇总
        <br />数据分发 <br />数据存取访问 <br />数据转换 <br />任务定制
        <br />监控管理
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">系统管理</div>
      <div class="li-text">
        运行环境配置
        <br />系统配置 <br />权限管理 <br />日志定制
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据交换</div>
      <div class="li-text">
        数据交换
        <br />消息管理 <br />队列管理 <br />发送管理 <br />接收管理
        <br />封装解析 <br />数据整编 <br />数据保存
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">安全认证管理</div>
      <div class="li-text">
        身份认证
        <br />单点登入 <br />加密认证 <br />
      </div>
    </div>
    <div class="biaoti mt40">
      <span class="el-icon-caret-bottom"></span>
      系统优势
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">多种交换方式</div>
      <div class="li-text">
        Web服务方式，支持主流的协议接口，如SOAP、REST等Web服务协议标准
        <br />数据库接口方式，支持主流关系型数据库的接口，包括了ORACLE、SQL
        SERVER、SYBASE、DB2等
        <br />基于文件交换方式，支持基于自定义格式的文件交换
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">多种数据格式</div>
      <div class="li-text">
        以XML格式为主，可支持各种类型的数据源的交换，包括关系型、结构化、XML、消息、文本数据、WEB数据、矢量地图、影像、视频、音频等
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据交换能力</div>
      <div class="li-text">
        支持服务、消息、数据库、文件等多种交换方式，并具有良好的负载均衡能力
        <br />支持大数据包的交换，进行分块传输，数据量大小不应受限制，在保证一定数据传输速度的同时，可避免占用全部网络带宽
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据交换传输</div>
      <div class="li-text">
        支持多种传输协议 <br />支持多种传输格式
        <br />支持数据的加/解密，基于PKI的安全认证 <br />支持传输层的动态扩展性
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">数据交换管理</div>
      <div class="li-text">
        交换安全管理，提供基于ACL的访问控制等安全管理技术
        <br />交换路由管理，提供基于动态路由的交换，可配置拓扑路径
        <br />交换协议管理 <br />交换性能管理 <br />交换日志管理
        <br />交换格式管理
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">批量数据交换</div>
      <div class="li-text">
        支持数据抽取、数据清洗、数据比对、数据校验、数据转换等处理，支持异构数据源或数据访问接口
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">实时数据同步</div>
      <div class="li-text">
        数据同步支持异构数据源之间的数据复制，应支持实时、定时、订阅/发布、手工等数据复制模式
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.qiye {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>