<template>
  <div class="shuiwu">
    <div ref="header"><Header :bg="bg"></Header></div>
    <div class="toubu">
      <div class="title">智慧工具产品</div>
      <div class="more">
        智慧工具为城市应用提供数据汇聚、处理、分析等全生命周期保障
      </div>
    </div>

    <div class="content">
      <div class="left" ref="xiding">
        <div class="nav">
          <div class="navbox">
            <div
              class="navson"
              v-for="(item, index) in navlist"
              :key="index"
              :class="type == item.type ? 'active' : ''"
              @click="type = item.type"
            >
              {{ item.name }}
              <div class="em"></div>
            </div>
          </div>
        </div>
        <div
          class="miaoshu"
          v-for="(item, index) in navlist"
          :key="index"
          v-show="type == item.type"
        >
          <div class="miaoshu-title">{{ item.title }}</div>
          <div class="biaoqianbox">
            <div class="some" v-for="(v, i) in item.biaoqian" :key="i">
              {{ v.name }}
            </div>
          </div>
        </div>
      </div>

      <div class="right" ref="right">
        <div class="daohang">
          <div class="weizhi">你的位置：</div>
          <div class="mingxi">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="mianbaoxie"
            >
              <el-breadcrumb-item :to="{ path: '/index' }"
                >首页</el-breadcrumb-item
              >
              <el-breadcrumb-item :to="{ path: '/productCenter' }"
                >产品中心</el-breadcrumb-item
              >
              <el-breadcrumb-item class="active">工具产品</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <Wulianwang v-if="type == -1"></Wulianwang>
        <Yujisuan v-if="type == 0"></Yujisuan>
        <Zuomian v-if="type == 1"></Zuomian>
        <Qiye v-if="type == 2"></Qiye>
        <Guanli v-if="type == 3"></Guanli>
        <Baohu v-if="type == 4"></Baohu>
        <Dili v-if="type == 5"></Dili>
      </div>
    </div>
    <Footer> </Footer>
    <div class="toback" ref="toback">
      <div class="toback-lianxi">
        <div class="toback-ph">
          <div class="toback-sons">
            <div class="toback-barss">
              <div class="toback-top">咨询电话</div>
              <div class="toback-bottom">133-3098-1239</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">技术支持</div>
              <div class="toback-bottom">189-8005-3442</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">售后电话</div>
              <div class="toback-bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="toback-wx">
          <div class="toback-son">
            <img src="@/assets/imgs/home/gongzonghao.png" alt />
            <div class="toback-tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <div class="toback-up" @click="up"></div>
    </div>
  </div>
</template>

<script>
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import Yujisuan from "../components/components/yunjisuan.vue";
import Zuomian from "../components/components/zuomian.vue";
import Qiye from "../components/components/qiye.vue";
import Guanli from "../components/components/guanli.vue";
import Baohu from "../components/components/baohu.vue";
import Dili from "../components/components/dili.vue";
import Wulianwang from "../components/components/wulianwang.vue";
export default {
  components: {
    Header,
    Footer,
    Yujisuan,
    Zuomian,
    Qiye,
    Guanli,
    Baohu,
    Dili,
    Wulianwang,
  },
  data() {
    return {
      type: -1,
      navlist: [
        {
          name: "物联网总线平台",
          type: -1,
          title: "物联网总线平台I-Ching",
          biaoqian: [
            { name: "背景需求" },
            { name: "产品概述" },
            { name: "技术架构" },
            { name: "应用架构" },
            { name: "系统特点" },
            { name: "运行环境" },
            { name: "经典场景" },
          ],
        },
        {
          name: "汉康云计算操作系统软件",
          type: 0,
          title: "汉康云计算操作系统软件",
          biaoqian: [
            { name: "功能" },
            { name: "资源池化共享" },
            { name: "构建云平台" },
            { name: "提供基础云服务" },
            { name: "云主机服务" },
            { name: "云存储服务" },
          ],
        },
        {
          name: "汉康桌面虚拟化系统软件",
          type: 1,
          title: "汉康桌面虚拟化系统软件",
          biaoqian: [
            { name: "功能" },
            { name: "统一门户" },
            { name: "运营管理" },
            { name: "云服务商能力考评" },
            { name: "业务监管" },
          ],
        },
        {
          name: "汉康企业数据仓库ETL系统软件",
          type: 2,
          title: "汉康企业数据仓库ETL系统软件",
          biaoqian: [{ name: "功能" }, { name: "系统优势" }],
        },
        {
          name: "汉康智慧城市数据管理平台",
          type: 3,
          title: "汉康智慧城市数据管理平台",
          biaoqian: [{ name: "功能" }],
        },
        {
          name: "汉康高可用不间断数据保护与恢复软件",
          type: 4,
          title: "汉康高可用不间断数据保护与恢复软件",
          biaoqian: [{ name: "核心功能" }, { name: "应用场景" }],
        },
        {
          name: "二三维一体化地理信息平台系统软件",
          type: 5,
          title: "二三维一体化地理信息平台系统软件",
          biaoqian: [{ name: "功能" }],
        },
      ],
      timer: null,
      arr: [],
      tobackarr: [],
      bg: null,
    };
  },
  methods: {
    scroll() {
      let scroll = document.body.scrollTop;

      //console.log("object :>> ", scroll);
      this.arr.push(scroll);
      // 判断导航栏显示位置
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];
        if (a - b >= 0) {
          //console.log("往下滑");
          if (scroll >= 300) {
            this.$refs.xiding.style.position = "fixed";
            this.$refs.xiding.style.top = "0";
            this.$refs.xiding.style.left = "0";
            this.$refs.right.style.marginLeft = "41vw";
          }
        } else {
          //console.log("往上滑");
          if (scroll <= 300) {
            this.$refs.xiding.style.position = "relative";
            this.$refs.xiding.style.top = "0";
            this.$refs.xiding.style.left = "0";
            this.$refs.right.style.marginLeft = "5.21vw";
            // this.$refs.right.style.backgroundColor = "#fff";
          }
        }
      }
    },

    up() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let sudu = 40;
      if (top >= 0 && top < 1000) {
        sudu = 40;
      } else if (top >= 1000 && top < 2000) {
        sudu = 60;
      } else if (top >= 2000 && top < 3000) {
        sudu = 100;
      } else if (top >= 3000 && top < 4000) {
        sudu = 140;
      } else {
        sudu = 200;
      }
      this.timer = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            sudu;

        if (top <= 0) {
          clearInterval(this.timer);
        }
      }, 10);

      // document.body.scrollTop = 0;
    },
    tobackscroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.tobackarr.push(scroll);
      //console.log("scroll :>> ", scroll);
      if (this.tobackarr.length > 2) {
        let a = [this.tobackarr[this.tobackarr.length - 1]];
        let b = [this.tobackarr[this.tobackarr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");

          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";

          if (scroll >= 470) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          // console.log("往上滑");
          if (scroll > 300) {
            this.$refs.header.style.position = "fixed";
            this.$refs.header.style.top = "0";
            this.$refs.header.style.left = "0";
            this.$refs.header.style.zIndex = "100";
            this.bg = "black";
          }
        }
        if (scroll > 120) {
          this.$refs.toback.style.opacity = "1";
        } else {
          this.$refs.toback.style.opacity = "0";
        }
        if (scroll <= 280) {
          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";
        }
      }
    },
  },
  mounted() {
    document.body.scrollTop = 0;
    window.addEventListener("scroll", this.scroll, true);
    window.addEventListener("scroll", this.tobackscroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
    window.removeEventListener("scroll", this.tobackscroll, true);
  },
};
</script>

<style lang="scss" scoped>
.shuiwu {
  width: 1920px;
  .toubu {
    width: 100%;
    height: 300px;
    background: url("../../../assets/imgs/chanping/shuiwu/bg.png");
    .title {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 24px;
      color: #ffffff;
      position: relative;
      top: 140px;
    }
    .more {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: normal;
      line-height: 22px;
      color: #ffffff;
      position: relative;
      top: 170px;
    }
  }
  .content {
    width: 100%;
    display: flex;
    .left {
      display: flex;
      .nav {
        width: 300px;
        height: 660px;
        background: rgba(255, 255, 255, 0.39);
        box-shadow: 2px 0px 6px rgba(184, 184, 184, 0.16);
        opacity: 1;
        padding-top: 96px;
        .navbox {
          display: flex;
          flex-direction: column;
          .navson {
            cursor: pointer;
            height: 40px;
            // display: flex;
            // align-items: center;
            text-align: right;
            font-size: 14px;
            font-family: Alibaba PuHuiTi Xi;
            font-weight: normal;
            line-height: 40px;
            color: #777777;
            //margin-bottom: 20px;
            padding: 0 16px;
            position: relative;
            .em {
              position: absolute;
              top: 0;
              right: -2px;
              width: 2px;
              height: 100%;
              background-color: rgba(68, 112, 255, 1);
              opacity: 0;
            }
          }
          .active {
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: normal;
            color: #181818;
            .em {
              opacity: 1;
            }
          }
        }
      }
      .miaoshu {
        margin-left: 70px;
        width: 316px;
        padding-top: 96px;
        .miaoshu-title {
          height: 75px;
          border-bottom: 1px solid rgba(232, 232, 232, 1);
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: normal;
          line-height: 25px;
          color: #181818;
        }
        .biaoqianbox {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          //  justify-content: space-between;
          margin-top: 25px;
          .some {
            padding: 10px;
            // width: 78px;
            height: 26px;
            background-color: rgba(247, 248, 250, 1);
            font-size: 14px;
            font-family: Alibaba PuHuiTi Xi;
            font-weight: normal;
            line-height: 20px;
            color: #888888;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 14px;
            margin-right: 14px;
          }
        }
      }
    }
    .right {
      width: 870px;
      margin-left: 100px;
      position: relative;

      .daohang {
        // position: absolute;
        // top: 0;
        // right: 0px;
        height: 96px;
        // padding: 50px 260px;
        display: flex;
        //flex-direction: column;
        align-items: center;
        justify-content: end;
        font-family: Alibaba PuHuiTi Xi;
        .weizhi {
          font-size: 12px;
          font-weight: 400;
          color: rgba(196, 196, 196, 1);
        }
      }
    }
  }
}
</style>