<template>
  <div class="dili">
    <div class="jieshao">
      系统是一款用于管理、传输、处理海量二三维空间数据的地理信息综合性平台，可以跨平台无缝汇集遥测影像、数字高程、矢量地图等各种空间地理数据。系统实现了对基于网络的全球尺度TB级海量空间数据的高效处理，有效的解决了多源、异构空间数据的集成问题，可实现跨平台无缝汇集及衔接问题。系统广泛应用于灾害监测、智慧城市、水利、交通、环保、旅游、国土、电力等多个行业。
    </div>
    <div class="jieshao">
      本平台提供了规范化的二次开发接口，能和规范的管理平台进行互联互通，并且实现一体化管理，让二三维一体化GIS应用平台具有更加广阔的商业应用前景。
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/21.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">海量数据管理功能</div>
      <div class="li-text">
        支持元数据的定义，可以创建、编辑和管理元数据。
        <br />支持对多源多类型空间数据的管理，包括矢量、栅格、影像、栅格目录、文本注记、网络、三维矢量、多分辨TIN等数据类型。支持业界先进的数据模型Geodatabase。
        <br />提供对OGC地图标准的广泛支持，包括WMS, WFS, WCS,
        和编目服务（Catalog
        Services)等。支持任意等级建立影像数据金字塔以及金字塔的部分更新，支持LZW、JPEG、JPEG2000等压缩技术存储影像。
        <br />支持多种矢量数据存储格式，如OGC的ST_Geometry格式、SQL
        Server的Geometry和Geography格式。支持OGC空间SQL语句直接访问矢量数据。
        <br />直接支持ENVI的文件格式，可直接读取、显示ENVI格式。
        <br />保证在DBMS中存储矢量数据的空间几何完整性，支持属性域、子类，支持定义空间数据之间的规则，包括关系规则、连接规则、拓扑规则等。
        <br />支持多版本数据管理技术。支持多用户并发编辑和访问。支持多级树状结构的地理数据模型级别的数据库复制和同步技术。
        <br />提供平台级（非二次开发）的角色服务安全访问控制LDAP，Token
        Service认证。
        <br />支持使用python脚本调用来完成地理空间数据的处理和分析。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">WebGIS功能</div>
      <div class="li-text">
        支持基于Web的在线地图数据编辑，包括点、线、面的空间数据编辑和属性编辑，支持多用户并发编辑。
        <br />支持基于Web在服务器端实现高级GIS分析功能，除了二维地图显示外，还包括三维地图显示、高级GIS空间分析（叠加分析、邻近分析、数据管理）等。
        <br />GIS平台软件提供GIS服务创建和管理框架，支持便捷的创建和管理二维/三维地图显示服务，影像服务，要素服务，搜索服务，几何服务。
        <br />提供免费的、可定制的三维数字地球客户端，可访问并使用服务器平台所发布的GIS服务，如三维GIS服务、在线GIS分析服务。
        <br />GIS平台软件必须通过OGC认证，提供证书复印件，支持发布各种开放数据格式，如WMS、WFS、WCS、KML等；并提供针对WMS的SLD支持。
        <br />提供GIS服务的创建和管理框架，基于此框架可以很方便地创建和管理三维地图显示服务，索引查询服务，要素编辑服务，OGC服务，远程空间数据库访问服务，地理定位服务，和自定义高级GIS分析服务、遥感影像服务。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">远程数据代理功能</div>
      <div class="li-text">
        通过远程数据代理，可实现对标准的WMS、WFS等OGC标准的空间数据共享、集成。
        <br />支持ESRI，ArcGIS，ArcGIS Server，SuperMap，MapGIS的数据访问。
      </div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">测站界面</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/22.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">地质灾害监测预警</div>
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/23.png" alt="" />
    </div>

    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/24.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/25.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/26.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.dili {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>