<template>
  <div class="yunjisuan">
    <div class="jieshao">
      汉康云计算操作系统是基于KVM架构自主研发的云计算平台。该产品以高可靠性和易管理为设计理念，实现智慧城市基础架构的建设，为公共安全等应用提供交付能力，在满足应用系统运行和日常办公的同时，提高工作效率、降低信息化建设成本和运维成本。<br />
      汉康云计算操作系统是一款针对桌面虚拟化和服务器虚拟化的完整虚拟化管理解决方案，是一个成熟的企业级虚拟化平台，提供了真正的策略虚拟化替代方案。
    </div>
    <div class="img mt40">
      <img src="../../../../assets/imgs/chanping/shebei/3.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">集中安全控制</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">高可用、高可靠集群</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">高可扩展</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">集中计算资源、集中存储资源，统一资源规划</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">易管理易维护</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">集中管理模式、远程管理、远程监控</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">降低环境复杂度</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">降低整合难度</div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">实用、环保</div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">资源池化共享</div>
      <div class="li-text">资源池化共享能力<br />按需分配计算资源给各应用</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/4.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">构建云平台</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/5.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">提供基础云服务</div>
    </div>

    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/6.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        服务器故障，虚拟机在其他服务器中，依策略自动重启<br />
        记录虚拟机在特定时间点的操作系统和应用程序
        <br />
        恢复虚拟机的操作系统和应用程序
      </div>
    </div>

    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/7.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        多虚拟机共享根镜像，各虚拟机保存差异数据<br />
        根据虚拟机实际磁盘占用，分配磁盘大小
        <br />
        多虚拟机的根镜像，缓存于内存或SSD中
      </div>
    </div>

    <div class="li mt40">
      <div class="dian"></div>
      <div class="li-title">云主机服务</div>
      <div class="li-text">
        服务器独立 <br />自行选择操作系统 <br />自行选择软件环境
        <br />使用权限不受影响 <br />硬件资源弹性调整 <br />硬件资源弹性调整
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">云存储服务</div>
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/8.png" alt="" />
    </div>
    <div class="li mt10">
      <div class="li-text">
        支持同时高并发读取数据 <br />数据存储安全有较高 <br />支持跨平台读取数据
        <br />支持热迁移、异地备份
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.yunjisuan {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>