<template>
  <div class="guanli">
    <div class="jieshao">
      基础数据管理平台基于信息资源、目录体系、交换体系，以人口、法人、宏观经济信息资源为基础，依托技术实现架构，通过提供目录服务和交换服务，实现专项应用、业务协同和资源共享，建设主要内容包括人口数据、法人数据、宏观数据、地理空间信息四大基础数据库以及视频等专题数据库。
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/12.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">发讯方式</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/13.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        信息直采 <br />数据集中 <br />融合分析 <br />主动服务
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">发讯方式</div>
    </div>
    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/14.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        数据挖掘贯穿业务流程的始终，在缩短投资回报周期的同时极大提高投资回报率。优化产品结构、降低管理成本、提高管理效率与管理水平，形成数据生态产业链
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">统一数据标准</div>
    </div>

    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/15.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="li-text">
        以统一标准汇集城市公共信息、城市运行信息、智慧应用信息，通过底层数据关联交互实现城市基础数据信息在行业间、应用间共享
      </div>
    </div>

    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">决策辅助支持</div>
    </div>

    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/16.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        基于社会治理与服务综合平台的基础数据，能够对社会治理的各个维度进行分析
        <br />将规划决策辅助库中与人口分布和配套设施分布相关的数据进行关联分析和展示，为政府进行学校、医院、体育场所、养老院、道路等城市公共设施规划建设提供辅助决策
        <br />基于大数据舆情分析系统，将事先建立的公共事件库中可能引发公共事件的问题在GIS系统上展示，并将上述预警信息推送给决策人员，做到公共重大事件的预警
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">企业征信服务</div>
    </div>

    <div class="img mt20">
      <img src="../../../../assets/imgs/chanping/shebei/17.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="li-text">
        通过接口、信息查询、信用报告、信用评分等方式，面向各类商业公司、征信机构提供企业征信服务
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.guanli {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>