<template>
  <div class="baohu">
    <div class="jieshao">
      高可用不间断数据保护与恢复系统共分为三部分：工作机模块、灾备机模块、控制机模块。工作机(Worknode)指的是用户的生产机，即常说的源端(Source)；灾备机(Backnode)指的是存放灾备数据的远程服务器，即常说的目标端(Target)；控制机(ctrcenter)则指的是灾备软件系统的控制平台所属的服务器，在控制机上部署完灾备软件的控制模块后，由于灾备软件的控制模块是通过Web界面实现配置和管理的，所以任何一台主机只要能通过浏览器访问控制机管理中心，则可进行相关的管理工作。
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/18.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/19.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      核心功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">镜像（Mirror）</div>
      <div class="li-text">
        初始化拷贝数据到灾备机/选择时间校验或严格校验方式比较两端数据，将剩余数据同步到灾备机。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">复制（Replication）</div>
      <div class="li-text">
        实时捕捉工作机监控目录的数据变化，将变化的数据以字节级同步到灾备机。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">恢复（Recovery）</div>
      <div class="li-text">
        恢复灾备机上的备份数据到任意一台工作机上，支持CDP数据恢复，快照恢复。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">Cluster支持</div>
      <div class="li-text">
        软件支持将Windows Cluster的共享存储中的数据备份到灾备机上。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">高可用（High Availability）</div>
      <div class="li-text">
        软件提供从主机到备机的自动切换，当主机发生故障自动切换到备机，从而保证对外提供的服务不中断。
      </div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">备份数据恢复</div>
      <div class="li-text">
        即时恢复即立即将当前灾备数据恢复到工作机上。默认设置为恢复到工作机的原目录。当然，用户可以指定其他的恢复目标路径。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">CDP恢复</div>
      <div class="li-text">
        除立即恢复外，用户也可通过CDP恢复将数据恢复到过去的任意一个时间点。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">快照恢复</div>
      <div class="li-text">
        除CDP之外，灾备软件也提供了较粗粒度的快照功能，如每隔一段时间对数据进行一个快照，这样当发现工作机的数据异常时，可通过灾备的快照，观察到以往快照点是的数据样本。
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">带宽优化</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/20.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.baohu {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>